<template>
  <div :class="customClass">
    <div class="app-header">
      <template v-if="loading"> Loading... </template>

      <template v-else>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

            <span v-else>
              <button :disabled="page <= 1" @click="page--">
                <i class="fas fa-chevron-left"></i>
              </button>

              {{ page }} / {{ pageCount }}

              <button :disabled="page >= pageCount" @click="page++">
                <i class="fas fa-chevron-right"></i>
              </button>
            </span>
          </div>

          <div>
            <i
              v-tooltip="{ content: 'Afișează toate paginile' }"
              class="fas fa-page-break"
              :style="showAllPages ? 'color: rgb(51, 153, 255)' : ''"
              style="padding: 7px"
              @click="showAllPages = !showAllPages"
            ></i>

            <i
              v-tooltip="'Descarcă'"
              class="fas fa-download"
              style="padding: 7px"
              @click="handleDownload"
            ></i>

            <i
              v-if="showClose"
              v-tooltip="'Închide'"
              class="fas fa-times"
              style=" color: red; padding: 7px"
              @click="closePdf"
            ></i>
          </div>
        </div>
      </template>
    </div>
    <div class="app-content">
      <vue-pdf-embed
        ref="pdfRef"
        height="1200"
        :source="pdfFile"
        :page="page"
        :annotationLayer="true"
        @rendered="handleDocumentRender"
   
      />
    </div>
  </div>
</template>

<script>

import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  props: {
    pdfFile: {
      require: true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    showClose: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      zoom: 1,
      loading: false,
      page: null,
      pageCount: 1,
      showAllPages: true,
    };
  },

  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    logt() {
console.log( this.$refs.pdfRef);


    },
    closePdf() {
      this.$emit("close-pdf");
    },
    handleDocumentRender(args) {
      this.loading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;

    },

    handleDownload() {
      this.$refs.pdfRef.download();
    },
  },
};
</script>
<style lang="scss">
.vue-pdf-embed > div {
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  overflow: auto;
  height: calc(100vh - 62px);
  background-color: #333;
}
</style>
